import {useEffect, useMemo, useState} from "react";

export default function useOnScreen(ref) {

  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
          if (entry.isIntersecting) setIsIntersecting(entry.isIntersecting);
        }
      ),
    [],
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}