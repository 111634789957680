import {
  Bullet,
  BulletFilling, MenuContainer,
  MenuItem,
  MenuItemBorder,
  MenuItems,
  MenuItemTextArea,
  MenuLogo,
  MenuName,
  MenuText
} from "./styles";

function Menu({workRef, knowledgeBaseRef}) {
  const scrollTo = (ref) => ref.current.scrollIntoView({behavior: "smooth"});
  const siteName = window.location.href.includes("kouhai") ? "Kouhai Software" : "what-has-phakhee-done";

  return (
    <MenuContainer>
      <MenuLogo
        src="https://firebasestorage.googleapis.com/v0/b/what-has-ian-done.appspot.com/o/kouhai%2FKouhai%20(NoBG).png?alt=media&token=6dd0f375-09d8-437d-b560-80bd1c980952"/>
      <MenuName>{siteName}</MenuName>

      <MenuItems>
        <MenuItem onClick={() => scrollTo(workRef)}>
          <Bullet><BulletFilling/></Bullet>
          <MenuItemTextArea>
            <MenuText>My work</MenuText>
            <MenuItemBorder />
          </MenuItemTextArea>
        </MenuItem>

        <MenuItem onClick={() => scrollTo(knowledgeBaseRef)}>
          <Bullet><BulletFilling/></Bullet>
          <MenuItemTextArea>
            <MenuText>Knowledge base</MenuText>
            <MenuItemBorder />
          </MenuItemTextArea>
        </MenuItem>
      </MenuItems>
    </MenuContainer>
  )
}

export default Menu;