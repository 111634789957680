import {
  Biography, BiographyText,
  GlitchBack, GlitchFront, GlitchMiddle,
  IntroContainer, Introduction, IntroText, Name, PreNameText,
} from "./styles";
import Menu from "./Menu";
import {useState} from "react";

function Intro({workRef, knowledgeBaseRef}) {
  const [writtenName, setWrittenName] = useState(false);
  const GLITCH_TEXT = "Portfolio";

  return (
    <IntroContainer>
      <Menu workRef={workRef} knowledgeBaseRef={knowledgeBaseRef} />

      <Introduction>
        <IntroText><PreNameText>Hi, my name is </PreNameText><Name>Phak Hee</Name></IntroText>
        <IntroText>
          Let's explore my <GlitchFront>{GLITCH_TEXT}</GlitchFront>
          <GlitchBack>{GLITCH_TEXT}</GlitchBack>
          <GlitchMiddle>{GLITCH_TEXT}</GlitchMiddle>
        </IntroText>
      </Introduction>

      <Biography>
        <div>
          <BiographyText
            onInit={(tw) => {
              tw.changeDelay(30)
                .typeString('Full name: Phak Hee Man')
                .callFunction(() => setWrittenName(true))
                .start()
            }}
          />
          {writtenName && (
            <BiographyText onInit={(tw) => {
              tw.changeDelay(30)
                .typeString('Education: BSc Software Engineering - HvA')
                .start()
              }}
            />
          )}
        </div>
        <div>
          <p>Based in <span>Amsterdam</span></p>
        </div>
      </Biography>
    </IntroContainer>
  )
}

export default Intro;