import styled, {keyframes} from "styled-components";

export const KnowledgeBaseContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100vw;
  padding: 100px 50px;
  justify-content: center;
  align-items: center;
  user-select: none;
  //background: #1B1A1C;
`;

export const KnowledgeBaseHeader = styled.p`
  font-family: Boston, serif;
  font-size: 48px;
  color: #F4F3F2;
  text-shadow: 0.5px 0.5px 0.5px rgba(222, 223, 223, 0.3);
  margin-bottom: 16px;
`;

export const KnowledgeBaseIntro = styled.p`
  font-family: Franklin, serif;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #F4F3F2;
  text-shadow: 0.5px 0.5px 0.5px rgba(222, 223, 223, 0.3);
  margin-bottom: 50px;
  width: 60%;
  text-align: center;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const AllKnowledge = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const KnowledgeSlideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
`;

export const Knowledge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 25px;
  margin: 4px;
  padding: 8px 16px;
  background: #2f3337;

  font-family: Franklin, serif;
  color: #F4F3F2;
  text-shadow: 0.5px 0.5px 0.5px rgba(222, 223, 223, 0.3);
  letter-spacing: 1px;
  
  animation: ${props => props.isVisible ? KnowledgeSlideDown : "none"} ${props => props.delay}s forwards;
`;