import styled, {keyframes} from "styled-components";
import Typewriter from 'typewriter-effect';

export const IntroContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100vw;
  height: calc(100dvh + 1px);
  padding: 50px 100px;
  user-select: none;
  position: relative;
  overflow: hidden;
  
  @media only screen and (max-width: 768px) {
    padding: 16px;
    height: auto;
    min-height: calc(100dvh + 1px);
  }
`;

export const IntroBackground = styled.img`
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -2;
  transform: scale(1.2);
  filter: brightness(50%);
`;

export const IntroText = styled.p`
  font-family: Franklin, serif;
  font-size: 36px;
  letter-spacing: 2px;
  color: #F4F3F2;
  text-shadow: 0.5px 0.5px 0.5px rgba(222, 223, 223, 0.3);
  position: relative;
  text-align: center;

  @media only screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

const SlideDown = keyframes`
  0%, 50% {
    opacity: 0;
    top: -40px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
`;

const SlideRight = keyframes`
  0% {
    opacity: 0;
    left: -40px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
`;

const SlideDownLast = keyframes`
  0%, 66.7% {
    opacity: 0;
    top: -40px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
`;

export const Introduction = styled.div`
  flex: 1 1 auto;
  min-height: 100px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  
  ${IntroText}:nth-child(2) {
    animation-name: ${SlideDownLast};
    animation-duration: 3s;
  }

  @media only screen and (max-width: 768px) {
    margin: 32px 0;
  }
`;

export const PreNameText = styled.span`
  position: relative;
  animation-name: ${SlideRight};
  animation-duration: 1s;
`;

export const Name = styled.span`
  font-family: Boston, serif;
  font-size: 48px;
  animation-name: ${SlideDown};
  animation-duration: 2s;
  position: relative;

  @media only screen and (max-width: 768px) {
    font-size: 36px;
  }
`;

const FadeIn = keyframes`
  0%, 80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const FrontGlitch = keyframes`
  0% {
    right: 0;
  }
  1%, 2% {
    right: 3px;
  }
  3%, 4% {
    right: -3px;
  }
  5%, 100% {
    right: 0;
  }
`;

const MiddleGlitch = keyframes`
  0% {
    right: 0;
    top: 0;
  }
  1%, 2%{
    right: -5px;
    top: -5px;
  }
  3%, 4% {
    right: 5px;
    top: 5px;
  }
  5%, 100% {
    right: 0;
    top: 0;
  }
`;

const MiddleGlitchSmallScreen = keyframes`
  0% {
    right: 0;
    bottom: 0;
  }
  1%, 2%{    
    right: -5px;
    bottom: 5px;
  }
  3%, 4% {
    right: 5px;
    bottom: -5px;
  }
  5%, 100% {
    right: 0;
    bottom: 0;
  }
`;

const BottomGlitch = keyframes`
  0% {
    right: 0;
    bottom: 0;
  }
  1%, 2% {
    right: 10px;
    bottom: -10px;
  }
  3%, 4% {
    right: -5px;
    bottom: 5px;
  }
  5%, 100% {
    right: 0;
    bottom: 0;
  }
`;

export const GlitchFront = styled.span`
  font-family: Qualta-Italic, serif;
  text-transform: uppercase;
  position: relative;
  color: #F4F3F2;
  animation: ${FrontGlitch} 2s infinite;

  @media only screen and (max-width: 364px) {
    top: 1px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

export const GlitchMiddle = styled.p`
  font-family: Qualta-Italic, serif;
  text-transform: uppercase;
  z-index: -1;
  position: absolute;
  color: #d3365c;
  animation: ${MiddleGlitch} 2s infinite;

  @media only screen and (max-width: 364px) {
    left: 0;
    animation: ${MiddleGlitchSmallScreen} 2s infinite;
  }

  @media only screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

export const GlitchBack = styled.p`
  font-family: Qualta-Italic, serif;
  text-transform: uppercase;
  z-index: -1;
  position: absolute;
  color: #448db6;
  animation: ${BottomGlitch} 2s infinite;

  @media only screen and (max-width: 364px) {
    left: 0;
  }

  @media only screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

const LocationSlideDown = keyframes`
  0%, 80% {
    opacity: 0;
    top: -40px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
`;

export const Biography = styled.div`
  width: 100%;
  display: flex;
  min-height: 40px;

  .Typewriter__wrapper {
    font-family: Source, serif;
    color: #F4F3F2;
  }

  .Typewriter__cursor {
    display: none;
  }

  @media only screen and (max-width: 768px) {
    flex-flow: column;
  }
  
  div:nth-child(2) {
    margin-left: auto;

    @media only screen and (max-width: 768px) {
      margin: 0;
    }
    
    p {
      color: #F4F3F2;
      font-family: Franklin, serif;
      animation-name: ${FadeIn};
      animation-duration: 4s;
      
      span {
        font-family: Amsterdam, serif;
        margin-left: 5px;
        position: relative;
        animation-name: ${LocationSlideDown};
        animation-duration: 5s;
      }
    }
  }
`;

export const BiographyText = styled(Typewriter)``;