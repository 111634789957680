import {
  Duration, Project,
  ProjectDescription,
  ProjectImage,
  ProjectInfo,
  ProjectLink,
  ProjectName,
  Skill,
  Skills
} from "../styles";
import useOnScreen from "../../../hooks/useOnScreen";
import {useRef} from "react";

function Work({project, delay}) {
  const ref = useRef(null);
  const isVisible = useOnScreen(ref);

  return (
    <Project isVisible={isVisible} ref={ref} delay={delay}>
      <ProjectImage src={project.image} />

      <ProjectInfo>
        <ProjectName>{project.name}</ProjectName>
        <Duration>{project.period}</Duration>
        {project.url && <ProjectLink href={project.url} target="_blank">{project.url}</ProjectLink>}
        <Skills>
          {project.skills.map(skill => <Skill>{skill}</Skill>)}
        </Skills>
        <ProjectDescription>{project.value}</ProjectDescription>
      </ProjectInfo>
    </Project>
  );
}

export default Work;