import {AnimationContainer, ContactContainer, EmailAddress, EmailIcon} from "./styles";
import {useRef} from "react";
import useOnScreen from "../../hooks/useOnScreen";

function Contact() {
  const contactRef = useRef(null);
  const isVisible = useOnScreen(contactRef);
  const email = window.location.href.includes("kouhai") ? "kouhaisoftware@gmail.com" : "mphakhee@gmail.com";

  return (
    <ContactContainer ref={contactRef}>
      <AnimationContainer>
        <EmailIcon
          src="https://firebasestorage.googleapis.com/v0/b/what-has-ian-done.appspot.com/o/kouhai%2Femail.png?alt=media&token=8cc5e8a1-d021-49d5-9096-972bdcd16388"
          draggable={false}
          isVisible={isVisible}
        />
      </AnimationContainer>
      <EmailAddress isVisible={isVisible}>E-mail: {email}</EmailAddress>
    </ContactContainer>
  )
}

export default Contact;