export const knowledgeBase = [
  "HTML",
  "CSS",
  "JavaScript",
  "React",
  "styled-components",
  "MobX",
  "Ionic Framework",
  "Python",
  "Java",
  "Spring Boot",
  "Go",
  "Express.js",
  "MySQL",
  "Kotlin",
  "Angular",
  "Firebase"
];