import Intro from "./Sections/Intro";
import {useEffect, useRef} from "react";
import KnowledgeBase from "./Sections/KnowledgeBase";
import Contact from "./Sections/Contact";
import {IntroBackground} from "./Sections/Intro/styles";
import AllWork from "./Sections/AllWork";

function App() {
  const workRef = useRef(null);
  const knowledgeBaseRef = useRef(null);

  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <>
      <IntroBackground src="https://firebasestorage.googleapis.com/v0/b/what-has-ian-done.appspot.com/o/kouhai%2Fgrain.gif?alt=media&token=524d49d3-a830-4f45-b829-edd9979665b9"/>
      <Intro workRef={workRef} knowledgeBaseRef={knowledgeBaseRef} />
      <AllWork workRef={workRef} />
      <KnowledgeBase knowledgeBaseRef={knowledgeBaseRef} />
      <Contact />
    </>
  );
}

export default App;