import {
  AllWorkContainer,
  AllWorkHeader,
  HeaderSubText,
  Projects
} from "./styles";
import {projects} from "../../constants/projects";
import Work from "./Work";

function AllWork({workRef}) {
  return (
    <AllWorkContainer ref={workRef}>
      <AllWorkHeader>Some of my <HeaderSubText>Work</HeaderSubText></AllWorkHeader>

      <Projects>
        {projects.map((project, i) => (<Work project={project} delay={i % 2 === 0 ? 0 : 0.5}/>))}
      </Projects>
    </AllWorkContainer>
  )
}

export default AllWork;