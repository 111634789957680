import styled, {keyframes} from "styled-components";

export const ContactContainer = styled.div`
  width: 100vw;
  padding: 50px 50px;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const SendEmailAnimation = keyframes`
  0% {
    opacity: 0;
    left: 0;
  }
  25% {
    opacity: 1;
    left: 50%;
  }
  50% {
    opacity: 1;
    left: 45%;
  }
  60%, 80% {
    opacity: 0;
    left: 100%;
  }
  90% {
    opacity: 0;
    left: calc(50% - 25px);
  }
  100% {
    opacity: 1;
    left: calc(50% - 25px);
  }
`;

export const EmailIcon = styled.img`
  opacity: 0;
  left: 0;
  width: 50px;
  position: absolute;
  animation: ${props => props.isVisible ? SendEmailAnimation : "none"} 6s forwards;
`;

export const DescriptionSlideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const EmailAddress = styled.p`
  font-family: Franklin, serif;
  color: #F4F3F2;
  text-shadow: 0.5px 0.5px 0.5px rgba(222, 223, 223, 0.3);
  letter-spacing: 2px;
  text-transform: lowercase;
  
  animation: ${props => props.isVisible ? DescriptionSlideDown : "none"} 1s forwards;
`;

export const AnimationContainer = styled.div`
  width: 25%;
  position: relative;
  user-select: none;
  margin-bottom: 66px;

  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`;