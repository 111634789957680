import {AllKnowledge, Knowledge, KnowledgeBaseContainer, KnowledgeBaseHeader, KnowledgeBaseIntro} from "./styles";
import {knowledgeBase} from "../../constants/knowledgeBase";
import {useRef} from "react";
import useOnScreen from "../../hooks/useOnScreen";

function KnowledgeBase({knowledgeBaseRef}) {
  const knowledgeRef = useRef(null);
  const isVisible = useOnScreen(knowledgeRef);

  return (
    <KnowledgeBaseContainer ref={knowledgeBaseRef}>
      <KnowledgeBaseHeader>Knowledge Base</KnowledgeBaseHeader>
      <KnowledgeBaseIntro>
        As a software engineer I am always learning new skills to apply to every project. Here are some notable ones
        I use frequently.
      </KnowledgeBaseIntro>

      <AllKnowledge ref={knowledgeRef}>
        {knowledgeBase.map((k, i) => (
          <Knowledge delay={i * 0.1} isVisible={isVisible}>
            <p>{k}</p>
          </Knowledge>
        ))}
      </AllKnowledge>

    </KnowledgeBaseContainer>
  );
}

export default KnowledgeBase;