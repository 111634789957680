export const projects = [
  {
    name: "Cook Me Something",
    position: "Personal",
    value: "Web application where users can look up ingredients and get recipes in return obtained using OpenAI that " +
      "contain these ingredients.",
    period: "Dec 2023",
    image: "https://firebasestorage.googleapis.com/v0/b/what-has-ian-done.appspot.com/o/kouhai%2Fscreencapture-cook-me-something-web-app-2023-12-08-05_56_58.png?alt=media&token=60127f23-dbf7-440a-863b-232b784f252c",
    skills: ["React", "OpenAI", "MobX", "styled-components", "Cloudflare Workers"],
    url: "https://cook-me-something.web.app/"
  },
  {
    name: "Crypto Tracker",
    position: "Personal",
    value: "To stay up to date with the modern Android development techniques, I developed an Android app using " +
      "Jetpack Compose that allows users to track the most popular cryptocurrency. The data needed is retrieved " +
      "using the CoinMarketCap API, which connection is made from a Cloudflare Worker. User authentication is done " +
      "using Firebase Authentication.",
    period: "Dec 2023",
    image: "https://firebasestorage.googleapis.com/v0/b/what-has-ian-done.appspot.com/o/kouhai%2Fsimplecryptotracker.png?alt=media&token=cb1c0713-9a94-4e90-9907-80ce74bd2e86",
    skills: ["Kotlin", "Jetpack Compose", "Firebase Auth", "Ktor", "Cloudflare Workers", "REST"],
    url: "https://github.com/phakhee/simple-crypto-tracker"
  },
  // {
  //   name: "Capgemini",
  //   position: "Internship",
  //   value: "At Capgemini I created a mobile app from scratch, which included conducting research, creating mockups, " +
  //     "interviewing employees and user testing. The development of the mobile app was done using Ionic framework " +
  //     "with React, backed with a Java Spring Boot back-end and a MySQL database. The mobile app contains " +
  //     "techniques such as live location tracking, websocket support for live updates and implementing the Mapbox " +
  //     "API, specifically the isochrone api.",
  //   period: "Feb 2023 - Jul 2023",
  //   skills: ["Ionic Framework", "React", "TypeScript", "MobX", "styled-components", "Java", "Spring Boot", "MySQL", "Mapbox API"]
  // },
  {
    name: "Duxly",
    position: "Freelance",
    value: "Developed an automation software in Python that makes it possible to process a csv file full of products " +
      "and for every product the image of it gets scraped and saved using requests. Also added threads support for " +
      "faster scraping. To find the perfect endpoint to scrape the images, I used Charles to intercept HTTP " +
      "requests.",
    period: "Feb 2023",
    image: "https://firebasestorage.googleapis.com/v0/b/what-has-ian-done.appspot.com/o/kouhai%2Ffewfew-01.png?alt=media&token=c966da6c-2c75-4ca1-bdd7-058d27c356d9",
    skills: ["Python"]
  },
  {
    name: "Kouhai",
    position: "Owner",
    value: "As my interest in web automation and sneakers grew, I developed a desktop application that automates checkout flows on popular websites " +
      "and mobile apps, which increases the chances of buying limited items. The development involved techniques such" +
      " as asyncio, threads and thread/process safe file processing with locks. To obtain the endpoints needed, I " +
      "used Charles with SSL proxying to intercept the HTTP requests done on my computer or mobile phone.",
    period: "Sep 2021 - Nov 2022",
    image: "https://firebasestorage.googleapis.com/v0/b/what-has-ian-done.appspot.com/o/kouhai%2Fkouhaiproof-01%20(2).png?alt=media&token=8cd807ae-c033-4f0e-a612-726acdf30d49",
    skills: ["Python", "Go", "Express.js", "Firebase"]
  },
  // {
  //   name: "Joboti",
  //   position: "Part-time",
  //   value: "At Joboti I fulfilled many tasks, these include: Adding customisation features to the web portal of " +
  //     "Joboti using React, which makes it possible for customers to customise their chatbot as much as they want. " +
  //     "Adding the chatbot on customer websites using Google Tag Manager, using vanilla HTML, CSS " +
  //     "and JavaScript so the chatbot fits the customers' needs and website style. And adding back-end features using " +
  //     "Python that feeds the web portal.",
  //   period: "Mar 2020 - Aug 2022",
  //   skills: ["React", "JavaScript", "MobX", "styled-components", "HTML", "CSS", "Python", "Django", "Google Tag Manager"]
  // },
  // {
  //   name: "Preyes",
  //   position: "Student",
  //   value: "With a team of three students, we developed a mobile app called Preyes. Preyes makes it possible for users" +
  //     " to add products from the dutch e-commerce platform Bol.com to their tracking list and set a discount goal. " +
  //     "When the goal gets met, the user gets notified. For this mobile app I primarily developed the front-end using " +
  //     "Kotlin, while also having pair programmed the back-end using Python with the rest of the team.",
  //   period: "Feb 2021 - Jun 2021",
  //   image: "https://firebasestorage.googleapis.com/v0/b/what-has-ian-done.appspot.com/o/kouhai%2Funknown.png?alt=media&token=49ea9340-d76b-4bb7-abf0-83ccca01a50d"),
  //   mobile: true,
  //   skills: ["Kotlin", "Ktor", "Python", "Django"]
  // },
  {
    name: "Plasma",
    position: "Freelance",
    value: "Plasma is an e-commerce application that already had an existing desktop application, but they needed to " +
      "extend it with two new screens. They provided the mockup designs, which I used to develop it pixel perfect " +
      "into a working desktop application.",
    period: "May 2021",
    image: "https://firebasestorage.googleapis.com/v0/b/what-has-ian-done.appspot.com/o/kouhai%2Fplasma%204.png?alt=media&token=963193eb-7613-4814-81c1-a757ea07b7da",
    skills: ["React", "JavaScript", "Electron", "MobX", "styled-components"]
  },
  {
    name: "1st2Notify",
    position: "Freelance",
    value: "1st2Notify is a community-based group that focuses on reselling limited items. I was tasked with " +
      "creating a dashboard where users can configure their settings within the group.",
    period: "Apr 2021",
    image: "https://firebasestorage.googleapis.com/v0/b/what-has-ian-done.appspot.com/o/kouhai%2F1st2notify.jpeg?alt=media&token=40ad3631-b4e0-4f22-9d6e-7fe8398cc3af",
    skills: ["React", "JavaScript", "MobX", "styled-components"]
  },
  {
    name: "Zonos",
    position: "Freelance",
    value: "Zonos is an e-commerce application that wanted to switch from a command line interface to a " +
      "graphical user interface, which I developed for them with a provided mockup. The desktop application was " +
      "developed using Electron with React, styled with styled-components and state management using MobX.",
    period: "Jan 2021 - Apr 2021",
    image: "https://firebasestorage.googleapis.com/v0/b/what-has-ian-done.appspot.com/o/kouhai%2Fzonos.jpg?alt=media&token=5ee315c0-d15c-4ea5-a75c-fcddd56b6ba7",
    skills: ["React", "JavaScript", "Electron", "MobX", "styled-components"]
  },
  // {
  //   name: "Joboti",
  //   position: "Internship",
  //   value: "During my internship at Joboti I applied my taught knowledge from university to the features in the web " +
  //     "portal of Joboti. The notable feature that was developed, was a dynamic tree form generated based on a JSON " +
  //     "object, which could be nested in any way. The primary technique used for this was recursion. I learned React " +
  //     "JS and Python through this internship.",
  //   period: "Sep 2020 - Jan 2021",
  //   skills: ["React", "JavaScript", "MobX", "styled-components", "Python"]
  // }
];