import styled, {keyframes} from "styled-components";
import {Knowledge} from "../KnowledgeBase/styles";

export const AllWorkContainer = styled.div`
  width: 100vw;
  padding: 50px 100px;

  @media only screen and (max-width: 768px) {
    padding: 16px;
  }
`;

export const AllWorkHeader = styled.p`
  font-family: Franklin, serif;
  font-size: 36px;
  letter-spacing: 2px;
  color: #F4F3F2;
  text-shadow: 0.5px 0.5px 0.5px rgba(222, 223, 223, 0.3);

  @media only screen and (max-width: 768px) {
    font-size: 24px;
  }
`;

export const HeaderSubText = styled.span`
  font-family: Boston, serif;
  font-size: 48px;

  @media only screen and (max-width: 768px) {
    font-size: 36px;
  }
`;

export const Projects = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
`;

const ProjectSlideDown = keyframes`
  0% {
    opacity: 0.1;
    transform: translateY(-40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const ProjectSlideLeft = keyframes`
  0% {
    opacity: 0.1;
    transform: translateX(40px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Project = styled.div`
  opacity: 0.1;
  flex: 0 0 calc(50% - 16px);
  height: fit-content;
  margin: 8px;
  padding: 16px;
  background: rgba(0,0,0,0.2);
  border-radius: 5px;
  animation: ${props => props.isVisible ? ProjectSlideDown : "none"} ${props => props.delay + 1}s forwards;

  @media only screen and (max-width: 768px) {
    flex: 0 0 calc(100% - 16px);
    animation: ${props => props.isVisible ? ProjectSlideLeft : "none"} ${props => props.delay + 1}s forwards;
  }
`;

export const ProjectImage = styled.img`
  width: 100%;
  border-radius: 5px;
`;

export const ProjectInfo = styled.div`
  margin-top: 16px;
`;

export const ProjectName = styled.p`
  font-family: Boston, serif;
  font-size: 24px;
  color: #F4F3F2;
  text-shadow: 0.5px 0.5px 0.5px rgba(222, 223, 223, 0.3);
  letter-spacing: 1px;
`;

export const Duration = styled.p`
  font-family: Franklin, serif;
  font-size: 16px;
  color: #F4F3F2;
  text-shadow: 0.5px 0.5px 0.5px rgba(222, 223, 223, 0.3);
  text-transform: lowercase;
  letter-spacing: 2px;
  filter: brightness(50%);
`;

export const ProjectLink = styled.a`
  margin-top: 16px;
  display: inline-block;
  text-transform: uppercase;
  font-family: Source, serif;
  color: #F4F3F2;
  word-break: break-all;
  text-decoration: none;
`;

export const Skills = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Skill = styled(Knowledge)`
  margin: 0 8px 8px 0;
`;

export const ProjectDescription = styled.p`
  margin-top: 16px;
  font-family: Franklin, serif;
  font-size: 16px;
  color: #F4F3F2;
  text-shadow: 0.5px 0.5px 0.5px rgba(222, 223, 223, 0.3);
  text-transform: uppercase;
  letter-spacing: 2px;
`;