import styled from "styled-components";

export const MenuContainer = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
  width: 100%;
  height: 100px;

  @media only screen and (max-width: 768px) {
    flex-flow: column;
    height: auto;
  }
`;

export const MenuLogo = styled.img`
  height: 100px;

  @media only screen and (max-width: 768px) {
    height: 75px;
  }
`;

export const MenuName = styled.span`
  font-size: 36px;
  letter-spacing: 2px;
  font-family: Franklin, serif;
  color: #F4F3F2;
  text-shadow: 0.5px 0.5px 0.5px rgba(222, 223, 223, 0.3);

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const MenuItems = styled.div`
  margin-left: auto;
  display: flex;

  @media only screen and (max-width: 768px) {
    margin: 16px 0 0 0;
  }
`;

export const BulletFilling = styled.div`
  background: #F4F3F2;
  width: 0;
  height: 100%;
  border-radius: 50%;
  transition: 0.2s;
`;

export const Bullet = styled.div`
  min-height: 15px;
  min-width: 15px;
  height: 15px;
  width: 15px;
  max-height: 15px;
  max-width: 15px;
  margin-right: 8px;
  border: 1px solid #F4F3F2;
  border-radius: 50%;
`;

export const MenuItemTextArea = styled.div``;

export const MenuItemBorder = styled.div`
  border-bottom: 1px solid #F4F3F2;
  width: 0;
  float: right;
  transition: 0.2s;
`;

export const MenuItem = styled.div`
  display: flex;
  margin: 0 32px;
  cursor: pointer;

  &:hover {
    ${Bullet} {
      ${BulletFilling} {
        width: 100%;
      }
    }
    
    ${MenuItemTextArea} {
      ${MenuItemBorder} {
        width: 90%;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    margin: 0 16px;
  }
`;

export const MenuText = styled.p`
  font-size: 16px;
  font-family: Franklin, serif;
  letter-spacing: 2px;
  color: #F4F3F2;
  text-shadow: 0.5px 0.5px 0.5px rgba(222, 223, 223, 0.3);
  text-transform: uppercase;
  margin-bottom: 8px;
`;